<template>
    <Dialog
        v-model:visible="visivel"
        :breakpoints="{'960px': '75vw', '640px': '90vw'}"
        :style="{width: '70vw'}"
        :maximizable="true"
        :modal="true"
        @show="carregarHistorico()"
        @hide="$emit('fechar')">

        <template #header >
            <div class="flex align-items-center">
                <i class="pi pi-book mr-1" style="font-size: 1.4rem"></i>
                <h3 style="margin:0px 5px;">Histórico</h3>
            </div>
        </template>

        <div class="grid">        
            <div class="col-12">
                <div class="flex align-items-center mt-3">
                    <div class="field mr-2">
                        <label>
                            <strong>Usuário</strong>
                        </label>

                        <InputText class="w-full" v-model="filterModal.usuario" />
                    </div>

                    <div class="field mr-2">
                        <label>
                            <strong>Movimento</strong>
                        </label>

                        <InputText class="w-full" v-model="filterModal.movimento" />
                    </div>
                    
                    <div class="field mr-2">
                        <label>
                            <strong>Período</strong>
                        </label>

                        <div class="flex align-items-center">
                            <div class="overflow-hidden" style="width:6rem;">
                                <Calendar
                                    class="w-full"
                                    v-model="periodoInicio"
                                    autocomplete="off"
                                    dateFormat="dd/mm/y"
                                />
                            </div>

                            <strong class="ml-2 mr-2">à</strong>
                            
                            <div class="overflow-hidden" style="width:6rem;">
                                <div class="p-inputgroup">
                                    <Calendar
                                        class="w-full"
                                        v-model="periodoFim"
                                        autocomplete="off"
                                        dateFormat="dd/mm/y"
                                        :minDate="periodoInicio"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mr-2 mt-2">
                        <div class="p-inputgroup">
                            <Button
                                label="Filtrar"
                                icon="pi pi-filter"
                                class="p-button-outlined p-button-info"
                                @click="carregarHistorico()"
                            ></Button>

                            <Button 
                                title="Limpar Filtro"
                                icon="pi pi-filter-slash"
                                class="p-button-outlined p-button-info"
                                style="margin-left: 1px;"
                                @click="limparFiltro()"
                            ></Button>
                        </div>
                    </div>
                </div>
                <DataTable 
                    responsiveLayout="scroll"
                    scrollDirection="both"
                    showGridlines
                    :value="lista">

                    <template #header>
                        <strong style="font-size: 16px;">{{ descricao }}</strong>
                    </template>
                    
                    <template #empty>
                        Nenhum registro encontrado.
                    </template>

                    <template #loading>
                        Carregando... Por favor, aguarde.
                    </template>

                    <Column field="dataCadastro" header="Data" style="width:11rem;">
                        <template #body="{ data }">
                            {{ formatDate(data.dataCadastro, 'DD/MM/YYYY HH:mm:SS') }} 
                        </template>
                    </Column>
                    <Column field="username" header="Usuário" style="width:10rem;" />
                    <Column field="movimento" header="Movimento" style="width:10rem;" />
                    <Column field="observacao" header="Observação">
                        <template #body="{ data }">
                            <ScrollPanel style="width: 100%; height:100%; max-height: 300px;">
                                <vue-json-pretty 
                                    v-if="isJsonString(data.observacao)"
                                    :data="data.observacao != undefined ? 
                                    JSON.parse(data.observacao) : ''"  
                                    show-line="false" 
                                    show-icon="true"/>
                                <span v-if="!isJsonString(data.observacao)">{{ data.observacao }}</span>
                            </ScrollPanel>                            
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <template #footer>

            <Paginator
                v-model:first="primeiraLinha"
                :rows="qtdRegistro"
                :total-records="totalRegistro"
                :rowsPerPageOptions="[3, 5, 10, 20, 30]"
                @page="onPage" />

        </template>
    </Dialog>
</template>

<script>
    import HistoricoGenericoService from '../service/HistoricoGenericoService';
    import Formatacao from '../utilities/Formatacao';
    import VueJsonPretty from 'vue-json-pretty';
    import 'vue-json-pretty/lib/styles.css';

    export default {
        emits: ['fechar'],
        components: { VueJsonPretty},
        props: {
			id: {
				type: String,
				required: false
			},
            descricao: {
				type: String,
				required: false
            },
            codigoPagina: {
				type: String,
				required: true
            },
            visivel: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                primeiraLinha: 0,
                totalRegistro: 0,
                qtdRegistro: 3,
                pagina: 0,
                lista: [],
                filterModal: {},
                estabelecimento: null,
                periodoInicio: null,
                periodoFim: null
            }
        },
        methods: {
            carregarHistorico() {
                if(!this.visivel) return;

                this.filterModal["id"] = this.id;
                this.filterModal["codigoPagina"] = this.codigoPagina;

                this.filterModal["page"] = this.pagina;
                this.filterModal["size"] = this.qtdRegistro;
                this.filterModal["sort"] = this.ordenar;
                this.filterModal["periodoInicio"] = undefined;
                this.filterModal["periodoFim"] = undefined;

                if(!!this.periodoInicio) {
                    this.filterModal["periodoInicio"] = this.formatDate(this.periodoInicio, "YYYY-MM-DD");
                }

                if(!!this.periodoFim) {
                    this.filterModal["periodoFim"] = this.formatDate(this.periodoFim, "YYYY-MM-DD");
                }
                
                HistoricoGenericoService.getHistoricoGenericoComPaginacao(this.filterModal)
                    .then(({ data }) => {
                        if(data) {
                            this.lista = data.content;
                            this.qtdRegistro = data.pageable.pageSize;
                            this.totalRegistro = data.totalPages * this.qtdRegistro;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.lista = [];
                        this.qtdRegistro = 0;
                        this.totalRegistro = 0;
                    });
            },

            limparFiltro() {
                this.filterModal["usuario"] = undefined;
                this.filterModal["movimento"] = undefined;
                this.filterModal["periodoInicio"] = undefined;
                this.filterModal["periodoFim"] = undefined;

                this.periodoInicio = null;
                this.periodoFim = null;

                this.carregarHistorico();
            },

            onPage(event) {
                this.pagina = event.page + 1;
                this.primeiraLinha = event.rows * event.page;
                this.qtdRegistro = event.rows; 

                this.carregarHistorico();
            },

            formatDate(data, format) {
                return Formatacao.formatDateCustom(data, format);
            },
            
            isJsonString(str) {
                try {
                    JSON.parse(str);
                } catch (e) {
                    return false;
                }
                return true;
            }
        }
    }
</script>

<style>
	textarea:disabled {
		opacity: 1 !important;
		cursor: not-allowed !important;
		background: #fff !important;
	}
</style>