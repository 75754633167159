import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class HistoricoGenericoService {

    getHistoricoGenericoComPaginacao(filterModal) {
        return axios.get(`${ ApiUrl.portal_url }api/pa/v1/historico/generico/${ filterModal.codigoPagina }/${ filterModal.id }`, {
            params: {
                page: filterModal.page,
                size: filterModal.size,
                sort: filterModal.sort,
                periodoInicio: filterModal.periodoInicio,
                periodoFim: filterModal.periodoFim,
                usuario: filterModal.usuario,
                movimento: filterModal.movimento
            }
        });
    }
}

export default new HistoricoGenericoService();